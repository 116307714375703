.ventana-emergente {
  background: white;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  font-family: Roboto-Light !important;
  color: var(--gray-html-active);
  z-index: 11;
  overflow: auto;
}

.container-translate-centrado {
  transform: translateX(-50%);
}

.container-translate-normal {
  transform: translateX(0%);
}

.header-table-confirmar-tentativo {
  position: relative;
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 0px;
  border-color: var(--gray-two-rcn);
  border-style: solid;
  box-sizing: border-box;
  padding: 5px 0px;
}

.alerta-dia {
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 0px;
  border-color: var(--gray-two-rcn);
  border-style: solid;
  box-sizing: border-box;
  color: var(--color-secundario);
  width: 100%;
  background-color: #FFFFAE;
  font-size: 12px;
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px;
  text-transform: uppercase;
}

.box-published {
  box-sizing: border-box;
  color: var(--color-secundario);
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 10px;
  text-transform: uppercase;
  border: 1px solid var(--gray-html-active);
  border-top: none;
  background-color: #EEEEEE;
}


.box-published .btn-publish {
  background-color: #fefefe;
  padding: 0.4rem 1.6rem;
  cursor: pointer;
  border: 1px solid #b0b0b0;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
}

.box-published .btn-des-publish {
  background-color: #fefefe;
  padding: 0.4rem 1.6rem;
  cursor: pointer;
  border: 1px solid #b0b0b0;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
}

.box-published .btn-publish:hover {
  background-color: var(--color-principal);
  color: #FFFFFF;
}

.box-published .btn-des-publish:hover {
  background-color: var(--red-color);
  color: #FFFFFF;
}

.box-equis-poup,
.box-equis-poup-mini {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 24px;
}

.box-equis-poup {
  width: 100px;
}

.box-equis-poup-mini {
  width: 80px;
}

.box-equis-poup img,
.box-equis-poup-mini img {
  transform: rotate(0deg);
  -webkit-transition: all .6s;
  -moz-transition: all .6s;
  -ms-transition: all .6s;
  -o-transition: all .6s;
  transition: all .6s;
  cursor: pointer;
}

.box-equis-poup img:hover,
.box-equis-poup-mini img:hover {
  transform: rotate(90deg);
  -webkit-transition: all .6s;
  -moz-transition: all .6s;
  -ms-transition: all .6s;
  -o-transition: all .6s;
  transition: all .6s;
}

.box-form-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 3rem 0rem;
  overflow: auto;
}

.box-form-create label {
  margin-bottom: 1rem !important;
  font-family: Roboto-Medium;
  font-size: 14px;
}


.title-popup {
  font-size: 2rem;
  display: block;
  width: 100%;
  text-align: center;
}

.content-popup {
  width: 100%;
  min-height: 100vh;
  padding: 4rem 1rem 0;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  /*overflow: auto;*/
}

.content-popup.grid-padding-x>.cell {
  padding-right: .9375rem;
  padding-left: .9375rem;
}

.content-popup2 {
  /*width: 100%;
	height: 100vh;
	padding: 3rem 1rem 0;
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	justify-content: center;*/
  overflow: auto;

  background-color: white;
  position: fixed;
  width: 500px;
  height: 350px;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.content-popup2.grid-padding-x>.cell {
  padding-top: 4.5rem;
  padding-right: .9375rem;
  padding-left: .9375rem;
}

.content-popup-3 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: calc(100vh - 50px);
  ;
  width: 100vw;
  background-color: white;
  z-index: 11;
  padding: 7rem 1rem 0 1rem;
  overflow-y: auto;
}

.wrapp-popup {
  width: 95%;
  margin: 0 auto;
}

.wrapp-popup app-tabla-general {
  display: block;
  margin-bottom: 0.5rem;
}

.modal-report {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 2rem 7%;
  background: white;
  overflow: auto;
}

.modal-report .header-page.sub-title {
  padding: 0 0.8rem;
  margin: 1.5rem auto;
}

.modal-report .header-page.sub-title span {
  display: block;
  border-bottom: 1px solid;
}


.modal-report .titulo-reporte {
  display: block;
  padding: 0.5rem;
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #cccccc;
  border-bottom: none;
  text-transform: uppercase;
  -webkit-print-color-adjust: exact;
}

.modal-report .content-reporte {
  height: calc(100vh - 80px);
  overflow: auto;
}

.titulo-dos-lineas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titulo-dos-lineas h5 {
  text-transform: uppercase;
}

/*REPORTE ESCENA*/
.px10-b {
  font-size: 10px;
  font-weight: bold;
}

.px10 {
  font-size: 10px;
}

.px12-b {
  font-size: 12px;
  font-weight: bold;
}

.px12 {
  font-size: 12px;
}

.px14-b {
  font-size: 14px;
  font-weight: bold;
}

.px14 {
  font-size: 14px;
}

.px15-b {
  font-size: 16px;
  font-weight: bold;
}

.px15 {
  font-size: 16px;
}

.px16-b {
  font-size: 16px;
  font-weight: bold;
}

.px16 {
  font-size: 16px;
}

.px17-b {
  font-size: 17px;
  font-weight: bold;
}

.px17 {
  font-size: 17px;
}

.px18-b {
  font-size: 18px;
  font-weight: bold;
}

.px18 {
  font-size: 18px;
}

.px20-b {
  font-size: 20px;
  font-weight: bold;
}

.px20 {
  font-size: 20px;
}

.px24-b {
  font-size: 24px;
  font-weight: bold;
}

.px24 {
  font-size: 24px;
}

.cabecera-reporte {
  display: flex;
  /*height: 60px;*/
  height: 75px;
  width: 100%;
  color: var(--color-secundario);
  margin-top: 30px;
}

.container-logo-export {
  border: 1px solid #0a0a0a;
  height: 100%;
  width: 15%;
  text-align: center;
}

.container-logo-export img {
  width: 95%;
  height: 100%;
  object-fit: contain;
}

.production-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #0a0a0a;
  height: 100%;
  width: 70%;
  padding: 0rem 1rem;
  line-height: 1;
}

.production-info2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0a0a0a;
  height: 100%;
  width: 85%;
  padding: 0rem 1rem;
  line-height: 1;
}

.production-info2>div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-head-titulo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid gray;
  height: 100%;
  width: 15%;
  padding: 0rem 1rem;
  line-height: 1;
}

.info-detail2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 15%;
  padding: 0rem 0.5rem 0rem 1rem;
  line-height: 1;
}

.info-detail2>div:last-child {
  margin-left: 1rem;
}

.container-filtro-print {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid gray;
  padding: 0.3rem 0.5rem;
  color: var(--color-secundario);
  font-size: 10px;
  height: 26.59px;
  min-height: 26.59px;
}

.container-filtro-print>div {
  display: flex;
  margin-right: 0.7rem;
}

.container-filtro-print-none {
  display: flex !important;
}

.container-filtro-print2 {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid gray;
  padding: 0.3rem 0.5rem;
  color: var(--color-secundario);
  font-size: 10px;
  justify-content: flex-start;
  height: 26.59px;
  min-height: 26.59px;
}

.container-filtro-print2>div:first-child {
  display: flex;
  margin-right: 0.7rem;
  align-items: center;
}

.container-filtro-print2>div:first-child>div {
  display: flex;
  margin-right: 0.7rem;
}

.container-filtro-print2-none {
  display: flex !important;
}

.report-element-title-row {
  border-bottom-color: var(--color-secundario) !important;
  /*border-top-color: var(--color-secundario) !important;*/
  border-left-color: var(--color-secundario) !important;
  border-right-color: var(--color-secundario) !important;
}


@media print {

  .modal-report {
    position: static;
    padding: 0;
    overflow: visible;
  }

  .cabecera-reporte {
    position: static;
    padding: 0;
    font-family: var(--print-font) !important;
  }

  .okplok {
    margin-bottom: 1.5rem;
    padding: 0;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: always;
  }

  .modal-report .header-page {
    display: none;
  }

  .modal-report .content-reporte {
    height: auto;
    overflow: visible;
  }

  .modal-report .titulo-reporte {
    display: block;
    padding: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
    /* border: 1px solid #cccccc; */
    border: 1px solid #000000;
    border-bottom: none;
    text-transform: uppercase;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: always;
    -webkit-print-color-adjust: exact;
    font-family: var(--print-font) !important;
  }

  .production-info,
  .production-info2,
  .container-filtro-print2 {
    font-family: var(--print-font) !important;
  }

}

@media screen and (max-width: 1280px) {
  .box-equis-poup {
    width: 90px;
  }
}

@media screen and (max-width: 425px) {
  .box-equis-poup {
    width: 80px;
  }
}