input.format-form,
select.format-form,
textarea.format-form{
	border: 0px solid var(--gray-html-active);
	height: 2rem;
  	-webkit-border-radius: 0px;
	border-radius: 0px;
	font-size: 14px;
	padding: 0rem 0.5rem;
    outline: 1px solid var(--gray-html-active);
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.5rem;
    background-color: transparent;
    background-position: right center !important;
    box-shadow: none;
	/*-moz-appearance: none;*/
	/*-webkit-appearance: none;*/
}

input.format-form:focus,
select.format-form:focus,
textarea.format-form:focus{
	border: 1px solid var(--gray-html-active);
	box-sizing: border-box;
}
textarea.format-form{
    min-height: 5rem;
    height: 6rem;
    resize: none;
    padding: 0.5rem 0.5rem;
}

textarea.format-form.ng-invalid:not(.ng-untouched){
    border: 2px solid #fc5353;
    outline: none;
}
app-input-file{
	width: 100%;
}

app-dropdown{
    margin-bottom: 0.5rem;
    display: block;
}


.button{
	background-color: white;
    color: var(--gray-html-active);
    border: 1px solid var(--gray-two-rcn);
    height: 2rem;
    font-size: 1.1rem;
    cursor: pointer;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -ms-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
}

.button[disabled]{
	opacity: 0.6;
}

.button1.expanded{
    width: 100%;
}

.form-gitter .grid-x>.cell{
    padding-right: .3rem;
    padding-left: .3rem;
}

.link-button{
    margin-top: 1.5rem;
    height: 34px;
    padding-top: 4px;
    text-align: center;
}
form.ng-dirty input.ng-invalid,
form.ng-dirty select.ng-invalid,
input.ng-dirty.ng-invalid, select.ng-dirty.ng-invalid
/*select.ng-invalid, input.ng-invalid*/
{
    background: #efe0e0;
    border: 1px solid #fe5050;
}

/* Estilos Adicionales Yojhan */
input.format-form.outline-plain{
    outline: 1px solid rgb(214, 214, 214);
}
input.format-form.custom-error{
    border: 1px solid #fe5050;
}