.hide-on-preview{
	display: none !important;
}

.print-table{
	width: 100%;
    table-layout: fixed;
}

@media print{
	@page {
		size: landscape
	}

	.tabla-general-content,
    .tabla-general .encabezados-tabla,
    .tabla-general .encabezados-tabla th,
    .tabla-general .encabezados-tabla th > div{
        font-family: var(--print-font) !important;
    }
	.top-menu{
		display: none;
	}

	.popup-create{
		position: static !important;
	}

	.content{
		padding: 0;
		overflow: visible;
	}

	.color-principal, .color-principal *{
		color: var(--color-principal) !important;
	}

	.hide-on-print{
		display: none !important;
	}

	.hide-on-preview{
		display: block !important;
	}

	app-footer{
		display: none;
	}

	.print-table{
		border-collapse: collapse;
	}

	.content-paginas{
		display: table;
	}

	.paginas{
		display: table-footer-group;
	}


	.paginas:after{
		counter-increment: page;
		content: "Page " counter(page) " of " counter(pages);
	}

	.element-hide{
		display: none;
	}

	.table-call-sheet,
	.print-table{
		font-family: var(--print-font) !important;
	}

	.header-report-tentative{
		font-size: 16px; 
		font-family: var(--print-font) !important;
	}
	.header-report-tentative div{
		color: #333 !important;
		-webkit-print-color-adjust: exact;
	}

}
