.filtroSeleccionado{
	display: flex;
	flex-wrap: wrap;
    /*width: 100%;*/
    height: auto;
    margin-bottom: 5px;
}
.filtroSeleccionado .opciones{
    /*background-color: rgb(240,240,240);*/
    font-family: 'Avenir-Book', sans-serif;
    font-size: .8rem;
    color: rgb(50,50,50);
    padding: 3px;
    margin: 2px 0px;
    text-transform: uppercase;
}
.filtroSeleccionado .opciones a.eliminarFiltro{
    display: inline-block;
    background-color: rgb(100,100,100);
    float: left;
    transform: translate(-3px, -3px);
    border-radius: 50%;
    height: 21px;
    width: 21px;
    padding: 2px 6px;
    color: white;
    font-size: .9rem;
    line-height: 1.5;
    -webkit-transition: all .2s;
	-moz-transition: all .2s;
	-ms-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s;
}
.filtroSeleccionado .opciones a.eliminarFiltro:hover{
    cursor: pointer;
    background-color: var(--color-principal);
    -webkit-transition: all .2s;
	-moz-transition: all .2s;
	-ms-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s;
}
