/* You can add global styles to this file, and also import other style files */
@import url("assets/css/modals.css");
@import url("assets/css/forms.css");
@import url("assets/css/filterinfo.css");
@import url("assets/css/print.css");
@import url("assets/css/css-switch.css");
@import url("assets/css/graficas.css");
@import url("assets/css/calendar.css");

:root {
  --ligth-grey-color: #F7F7F7;
  --ligth-purple-color: #FCEAFF;
  --ligth-blue-color: #CFF3FC;
  --ligth-orange-color: #FFF0AC;
  --ligth-yellow-color: #FCFBB0;
  --ligth-green-color: #DCFABD;
  --ligth-red-color: #FFC7DE;
  --grey-color: #D1CFCF;
  --purple-color: #F0ADF9;
  --dark-green-color: #1eb60e;
  --blue-color: #19CFF9;
  --orange-color: #FCD70D;
  --yellow-color: #FFFD38;
  --green-color: #B7FA72;
  --grey-lite: #847F7F;
  --white-dark: #e9e9e7;
  --white: #FFFFFF;
  --red-color: rgb(255, 0, 103);
  --black-color: rgb(50, 50, 50);
}

.flex-grow {
  flex: 1;
}

.o-auto {
  overflow: auto;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  /*display: inline-block;*/
  /*width: 60px;*/
  height: 27px;
  /*34px;*/
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transform: translateX(-41%);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  /*26px*/
  width: 1rem;
  /*26px*/
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: var(--color-principal);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--color-principal);
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

input:disabled,
input[type="text"]:disabled,
.input-disabled {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: var(--gray-html-active);
}

input[type="submit"]:disabled {
  background-color: #e6e6e6;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  height: 1.4rem;
  width: 2.3rem;
}

.slider.round:before {
  border-radius: 50%;
}

.tabla-general .encabezados-tabla th.order-desc>div:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  right: 4px;
  bottom: 50%;
  transform: translateY(50%);
}

.tabla-general .encabezados-tabla th.order-asc>div:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  right: 4px;
  bottom: 50%;
  transform: translateY(50%);
}

.tabla-general tbody tr:nth-child(even) {
  background-color: var(--gray-light-back-rcn);
}

table tr:nth-child(even) td.sticky-left.editable,
table tr:nth-child(even) td.td-data.editable,
td.editable {
  cursor: pointer;
  background-color: rgb(206 221 255);
  text-decoration: underline;
}

.sticky-fila1 {
  position: sticky !important;
  top: 0px !important;
}

.sticky-fila2 {
  position: sticky !important;
  top: 29px !important;
}

.sticky-fila2.reporte {
  top: 36px !important;
}

.owl-carousel.owl-theme.owl-loaded {
  display: flex;
  flex-direction: column;
}

/*.owl-item{
	cursor: initial;
}*/

.owl-item.active:hover {
  color: var(--gray-html-active);
  cursor: initial;
}

/*.owl-stage-outer.ng-star-inserted{
  order: 1;
  height: 24rem;
  min-height: 24rem;
  max-height: 24rem;
}*/
.owl-dots.ng-star-inserted {
  order: 2;
  zoom: 1.5;
  height: 1rem;
}

.owl-dots.ng-star-inserted .owl-dot span {
  background-color: white;
  border: 1px solid #707070;
}

.owl-dots.ng-star-inserted .owl-dot.active span {
  background-color: #cccccc;
  border: 0px solid #707070;
  transform: scale(1.4);
}

.owl-nav.ng-star-inserted {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 0px;
  order: 3;
  height: 5rem;
}

/*.owl-nav.ng-star-inserted .owl-next,
.owl-nav.ng-star-inserted .owl-prev{
	margin: 0px;
	width: 300px;
	background-color: white;
	border-radius: 0px;
	border: 0px solid var(--gray-two-rcn);
	
}

.owl-nav.ng-star-inserted .owl-next:hover,
.owl-nav.ng-star-inserted .owl-prev:hover{
	background-color: white !important;
	border-radius: 0px;
	border: 0px solid var(--color-red-omitir) !important;
}*/

.preview-scene-auto {
  width: 100%;
  overflow: auto;
}

.box-container {
  padding: 0.5rem;
  border: 2px solid #cccccc;
}

.script-view {
  min-height: 792px;
  max-height: 792px;
  height: 792px;
  width: 612px;
  min-width: 612px;
  max-width: 612px;
  overflow: auto;
  /*background: url(/assets/img/lineas_fondo5.jpg) top center;*/
  background-size: 792px 612px;
  /* filter: grayscale(100%); */
  padding: 2.2rem 6.7rem 3rem;
}

.script-view-editar {
  min-height: 792px;
  max-height: 792px;
  height: 792px;
  width: 412px;
  min-width: 412px;
  max-width: 412px;
  overflow: auto;
  /*background: url(/assets/img/lineas_fondo5.jpg) top center;*/
  background-size: 792px 612px;
  filter: grayscale(100%);
  padding: 2.2rem 1rem 3rem 2rem;
}


.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: 12;
  position: fixed;
}

.container-loader .loader {
  background: url("/assets/img/loader.gif") no-repeat fixed center center;
  height: 100px;
  width: 100px;
  background-size: 100px;
}

.cap-especiales {
  color: #b36bf2;
}

.min-loader {
  width: 50px;
  margin: 0 auto;
}

.popup-create-mini {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  font-family: Roboto-Light;
  color: var(--gray-html-active);
  z-index: 10;
  /*overflow: auto;*/
}

.popup-create-mini .box-form-create label {
  font-weight: bold !important;
}

.porcent-width-50 {
  width: 50.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(50%);
}

.popup-create {
  background: white;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  font-family: Roboto-Light;
  color: var(--gray-html-active);
  z-index: 12;
  overflow: auto;
}

.popup-create .box-form-create label {
  font-weight: bold !important;
}

.header-page {
  display: inline-block;
  width: 100%;
  font-family: Roboto-Light;
  font-size: 28px;
  color: rgb(120, 120, 120);
  padding: 0px;
  margin: 0px;
  text-transform: uppercase;
  line-height: 1.2;
  min-height: 40px;
  position: relative;
}

.header-page.auto {
  min-height: auto;
  height: auto;
}

.header-page span {
  font-size: 1rem;
  display: block;
  padding-top: 0.5rem;
}

.header-page h3 {
  color: var(--color-secundario);
  margin-bottom: 0;
  line-height: 40px;
}

.header-page .icons {
  display: flex;
  float: right;
  position: absolute;
  top: 19px;
  right: 0px;
}

.search-wrapper {
  display: flex;
  position: relative;
}

.search-wrapper>input {
  margin-bottom: 0;
}

.search-wrapper .icon-search-close {
  position: absolute;
  right: 5px;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  width: 20px;
  justify-content: center;
}
.container-icono-search{
  display: flex;
  align-items: center;
  min-height: 40px;
}

.icon-search {
  /* display: inline-block;
  height: 40px;
  width: 240px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-principal);
  border-radius: 25px;
  margin-left: 4px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 24px; */
  width: 240px;
  margin: 0;
  height: 30px;
  padding-left: 15px;
  padding-right: 24px;
}

.icon-search-close {
  position: relative;
  right: 30px;
  top: 1px;
  cursor: pointer;
  padding: 0 !important;
  font-size: 1.3rem !important;
  font-weight: bold;
  width: 30px;
  text-align: center;
}
.icon-search-close:hover{
  color: var(--color-principal);
}

.icon-search-2 {
  display: inline-block;
  height: 20px;
  width: 200px;
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  margin-left: 4px;
  margin-bottom: 0px;
  cursor: pointer;
  padding: 0px 24px 0px 15px;
  font-size: 0.8rem;
}

.icon-search-close-2 {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}

.icon2 {
  height: 30px;
  width: 30px;
  border: solid 1px var(--color-principal);
  border-radius: 25px;
  margin-left: 4px;
  cursor: pointer;

  line-height: 1;
  text-transform: none;
  font-family: 'Material Icons';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}

.icon2-s {
  border: solid 0px var(--color-principal) !important;
  border-radius: 25px !important;
}

.icon {
  height: 40px;
  width: 40px;
  line-height: 1;
  text-transform: none;
  font-family: 'Material Icons';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  cursor: pointer;
  border-radius: 50%;
}
.modal-report .icon{
  color: rgba(0, 0, 0, 0.7);
}

.icon.mid {
  transform: scale(0.85);
}

.icon.small {
  transform: scale(0.65);
}

.icon.mini {
  transform: scale(0.5);
}

.icon:hover {
  border-color: var(--color-principal);
  color: var(--color-principal) !important;
}

.icon2:hover {
  border-color: var(--color-principal);
}

.icon.filter::before {
  content: 'filter_alt';
}

.icon.create::before {
  content: 'add';
}

.icon2.create::before {
  content: 'add';
}

.icon.tentativo::before{
  content: 'event_note';
}

.icon.receive::before{
  content: 'download';
}

.icon.upload::before {
  content: 'publish';
}

.icon.config::before {
  content: 'settings_applications';
}

.icon.esc-per::before {
  content: 'recent_actors';
}

.icon.esc-loc::before {
  content: 'burst_mode';
}

.icon.esc-set::before {
  content: 'videocam';
}

.icon.esc-eve::before {
  content: 'assistant';
}

.icon.export::before {
  content: 'download';
}

.icon.user::before {
  content: 'person_outline';
}

.icon.editar::before{
  content: 'border_color';
  font-size: 1.5rem;
}

.icon2.editar::before {
  content: 'border_color';
  font-size: 1.2rem;
}

.icon.borrar::before {
  content: 'delete_outline';
}

.icon.borrar.white-icon {
  margin: 0;
  border: 0;
  font-size: 1.5rem;
  border: none;
  font-style: normal;
}

.icon.editar.white-icon {
  filter: brightness(100);
  margin: 0;
  border: 0;
  border: none;
  font-style: normal;
}

.icon2.borrar::before {
  content: 'delete_outline';
}

.icon.adjuntar::before {
  content: 'attach_file';
}

.icon.buscar::before{
  content: 'search';
}

.icon.regresar::before {
  content: 'arrow_back';
}

.icon.libreto::before{
  content: 'feed';
}

.icon-alerta,
.icon-warning-horizontal {
  height: 30px;
  text-transform: none;
  font-family: 'Material Icons';
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-style: normal;
  font-size: 1.6rem;
}

.icon-alerta::before,
.icon-warning-horizontal::before {
  content: 'error_outline';
}

.icon.antes{
  border: none;
}

.icon.antes::before {
  content: 'arrow_back_ios';
  border: none;
}
.icon.despues{
  border: none;
}

.icon.despues::before {
  content: 'arrow_forward_ios';
  border: none;
}

.icon.min-icon {
  height: 30px;
  width: 30px;
}

.icon.editar.min-icon::before {
  font-size: 1rem;
}

.icon.borrar.min-icon {
  font-style: normal;
  font-size: 1.3rem;
}
.icon-black {
  border: solid 1px var(--color-secundario);
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.6rem;
}
.icon-black2 {
  border: solid 1px var(--color-secundario);
  color: rgba(0, 0, 0, 0.6);
}
.icon-black3 {
  border: solid 1px var(--color-secundario) !important;
  color: rgba(0, 0, 0, 0.6);
}
.icon-black3:hover {
  border: solid 1px var(--color-principal) !important;
  color: var(--color-principal);
}

.icon.agregar-continuidad::before {
  content: 'photo_camera';
}

.icon.calendario-continuidad::before{
  content: 'calendar_today';
}

.icon.tag-maquillaje::before {
  content: 'palette';
}

.icon.tag-maquillaje:hover,
.icon.tag-peinado:hover,
.icon.tag-vestuario:hover ,
.icon.tag-props:hover {
  color: white !important;
}

.icon.tag-props::before {
  content: 'liquor';
}

.icon.tag-vestuario::before {
  content: 'checkroom';
}

.icon.tag-peinado::before {
  content: 'sports';
}

.edit-button {
  position: relative;
}

.error-alert {
  padding: 0.5rem;
  background: white;
  /*#f3c1c1*/
  width: 100%;
  color: red;
  /*#ffffff*/
  border: 2px solid #f58181;
  margin-bottom: 0.5rem;
  text-align: center;
}

.header-navigation {
  text-align: center;
  margin-bottom: 0.5rem;
  position: relative;
}

.header-navigation .arrow {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
}

.header-navigation .title-navigation {
  font-weight: bold;
}

.header-navigation .arrow.prev {
  left: 0rem;
}

.header-navigation .arrow.next {
  right: 0rem;
}

.disabled {
  cursor: not-allowed;
  color: var(--gray-html-disabled);
}

.active {
  cursor: pointer;
  color: var(--gray-html-active);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
}

.active:hover {
  color: var(--color-principal);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.hover {
  color: var(--color-principal) !important;
}

.no-clickable {
  cursor: initial !important;
}

.flex {
  display: flex;
}

.editable-hour {
  cursor: pointer;
  color: rgb(179, 107, 242);
}

.table-call-sheet {
  width: 100%;
  margin-top: 12px;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.table-call-sheet tr {
  border-left: 1px solid #0a0a0a;
  border-top: 1px solid #0a0a0a;
}

.table-call-sheet th,
.table-call-sheet td {
  border-right: 1px solid #0a0a0a;
  border-bottom: 1px solid #0a0a0a;
  text-transform: uppercase;
}

.table-call-sheet th {
  font-weight: bold;
  padding: 4px;
}

.table-call-sheet td {
  padding: 6px 10px;
}

.table-call-sheet td.no-bottom {
  border-bottom: none;
}

.row.no-gap {
  margin-left: -2px;
  margin-right: -2px;
}

.header-report-tentative {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 0.5rem;
  line-height: 0.9;
}

.header-report-tentative div {
  text-transform: uppercase;
  font-weight: bold;
}

.material-icons,
.material-icons-outlined {
  font-size: 24px;
}

.table-clap-wrapper {
  width: 100%;
  overflow: auto;
}

.table-clap {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  font-family: "Avenir-Book";
}

.table-clap tr {
  border-left: 1px solid var(--gray-two-rcn);
  border-top: 1px solid var(--gray-two-rcn);
}

.table-clap thead tr {
  border-top: 1px solid #ffffff;
}

.table-clap th,
.table-clap td {
  border-right: 1px solid var(--gray-two-rcn);
  border-bottom: 1px solid var(--gray-two-rcn);
}

.table-clap th {
  background: var(--color-secundario);
  font-weight: bold;
  font-size: 12px;
  color: white;
  padding: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
  font-family: "Avenir-Book";
  box-sizing: border-box;
  text-transform: initial;
}

.table-clap th > div{
  font-weight: bold;
}

.table-clap th.gap {
  width: 50px;
  background: white;
  border: none;
}

.table-clap td {
  padding: 4px 6px;
  position: relative;
  font-size: 13px;
  color: var(--color-secundario);
  text-transform: uppercase;
}


.table-clap td.sticky-left,
.table-clap th.sticky-left {
  position: sticky;
  left: 0;
  z-index: 1;
  background: #ffffff;
}

.table-clap td .td-container {
  text-transform: uppercase;
  display: inline-block;
  height: auto;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-clap td:hover .td-container {
  overflow: visible !important;
  white-space: normal !important;
  word-break: break-word !important;
}

.table-clap td input {
  margin: 0;
  font-size: 13px;
  height: 1.8rem;
}

.table-clap td input:read-only {
  background-color: #efecec;
}

.table-clap tfoot tr td {
  position: sticky;
  bottom: 0;
  background: white;
}

.table-clap .align-right {
  text-align: right;
}

.table-clap .align-center,
.align-center {
  text-align: center;
}

th.option-table,
td.option-table {
  position: sticky;
  right: 0;
  width: 50px;
}

td.option-table {
  cursor: pointer;
  background-color: rgb(247, 253, 242);
}

th.option-table>div,
td.option-table>div {
  width: 50px;
}

.more-menu {
  color: var(--color-principal);
  font-size: 2rem;
  cursor: pointer;
}

.more-menu.filled {
  background-color: var(--color-principal);
  color: #fff;
}

.more-menu.circle {
  font-size: 1.6rem;
  border: 1px solid var(--color-principal);
  border-radius: 50%;
  height: 27px;
  width: 27px;
}

.option-table .more-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-only {
  display: none;
}

.pointer {
  cursor: pointer;
}

.seccion-sin-registros {
  padding: 0.5rem;
  text-align: center;
  border-bottom: none;
  font-weight: bold;
  border: 1px solid var(--gray-two-rcn);
  background: var(--gray-two-rcn);
  color: white;
}

hr.space {
  margin: 0;
  margin-bottom: 0.5rem;
}

.content-wrapper {
  height: calc(100vh - 108px);
  overflow: auto;
  display: flex;
  flex-flow: column;
}

/* Data container */
.data-container {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 6px;
}

.data-container .data-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 36px;
  text-align: left;
  text-transform: uppercase;
  padding: 9px;
  position: relative;
  box-shadow: 1px 0 0 0 var(--color-secundario),
    0 1px 0 0 var(--color-secundario),
    1px 1px 0 0 var(--color-secundario),
    1px 0 0 0 var(--color-secundario) inset,
    0 1px 0 0 var(--color-secundario) inset;
}

.data-container .data-header>p {
  margin-bottom: 0;
  font-weight: bold;
  line-height: 1;
}

.data-container .data-header .accordeon {
  position: absolute;
  top: 0px;
  right: 65px;
}

.data-container .data-header .button-container .accordeon {
  display: inline-block;
  position: relative;
  top: -1px;
  right: auto;
}

.data-container .data-header .button-container {
  position: absolute;
  top: 50%;
  right: 0px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}

.data-container .data-header .button-container .mat-icon {
  display: flex;
  justify-content: center;
}

.data-container .loader {
  width: 100%;
  display: flex;
  height: calc(100% - 36px);
  align-items: center;
  justify-content: center;
}

.data-container .data-body {
  position: relative;
  height: calc(100% - 36px);
  overflow: auto;
  box-shadow: 1px 0 0 0 var(--color-secundario),
    0 1px 0 0 var(--color-secundario),
    1px 1px 0 0 var(--color-secundario),
    1px 0 0 0 var(--color-secundario) inset,
    0 1px 0 0 var(--color-secundario) inset;
}

.data-container .data-body.standalone-legends {
  height: calc(100% - 65px);
}

.data-container .data-body.legend-body {
  display: flex;
  flex-flow: column;
}

.data-container .data-header .btn-arrow {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

button.mat-icon {
  cursor: pointer;
}

button.mat-icon:hover {
  color: var(--color-principal);
}

.material-icons.sm {
  font-size: 14px;
}

.header-page.top-header{
  position: fixed;
  top: 0;
  right: 90px;
  height: 50px;
  left: 50px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-page.top-header >.icons{
  top: 5px;
  margin-right: 10px;
}
.header-page.top-header >span,
.header-page.top-header h3{
  color: white;
}
.header-page.top-header >span{
  padding-top: 0;
  margin-top: 4px;
  font-size: 0.7rem;
  font-weight: bold;
}
.header-page.top-header h3{
  font-size: 1.1875rem;
  line-height: 25px !important;
  font-weight: bold;
}
.loader-filters-banner{
	background: url(/assets/img/loader.gif) no-repeat center center;
    height: 50px;
    width: 50px;
    background-size: 100%;
}

@media print {

  .option-table {
    display: none;
  }

  .tabla-general-content {
    overflow: visible;
    max-height: none;
  }

  .table-clap,
  .table-clap th,
  .table-clap th>div {
    font-family: var(--print-font) !important;
  }

  .table-clap {
    margin-bottom: 0;
    border: solid #000 !important;
    border-width: 1px 0 0 1px !important;
  }

  .table-clap th {
    page-break-inside: avoid;
    page-break-after: always;
    background-color: #eeeeee !important;
    -webkit-print-color-adjust: exact;
    font-size: 15px;
    border: solid #0a0a0a !important;
    border-width: 1px !important;
    color: #0a0a0a;
  }

  .table-clap th>div {
    color: #333 !important;
    -webkit-print-color-adjust: exact;
    font-size: 15px;
  }

  .table-clap td {
    white-space: normal !important;
    /* font-size: 0.65rem; */
    border: solid #0a0a0a !important;
    border-width: 1px !important;
    font-size: 13px;
    padding: 0.3rem 0.2rem;
    word-break: break-word;
  }

  .table-clap td .td-container {
    width: auto !important;
    overflow: visible;
    color: #0a0a0a;
    font-weight: bold;
  }

  .table-clap td span {
    color: #0a0a0a;
    font-weight: bold;
  }

  .seccion-sin-registros {
    font-weight: bold;
    color: #0a0a0a;
    font-family: var(--print-font) !important;
  }
}

/* Tablet landscape */
@media screen and (max-width: 1280px) {

  .mobile-only {
    display: initial;
  }

  .table-clap th {
    font-size: 10px;
    padding: 3px;
  }

  .table-clap td {
    font-size: 11px;
    padding: 4px;
  }

  .header-page {
    display: block;
    height: auto;
    margin-bottom: 6px;
    min-height: auto;
  }

  .header-page span {
    display: block;
    padding: 0;
  }

  .header-page h3 {
    margin: 0;
  }

  .seccion-sin-registros {
    font-size: 13px;
  }
}

.panel-error {
  position: fixed;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 10px 114px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 9;
  height: 43px;
  border: 1px solid black;
}

@media screen and (max-width: 1000px) {
  .header-page.top-header >.icons{
    top: auto;
    bottom: 0;
    position: initial;
    width: 100%;
    margin: 0;
    height: 50px;
    z-index: 100;
    background: red;
    background-color: var(--color-secundario);
    align-items: center;
    justify-content: center;
  }
  .header-page.top-header h3 {
    font-size: 0.9rem;
    line-height: 15px !important;
    font-weight: bold;

  }
  .icon-search-close{
    font-size: 1.5rem !important;
  }
}
/* Tablet portrait */
@media screen and (max-width: 800px) {}

/* Small devices */
@media screen and (max-width: 600px) {
  .header-page span {
    font-size: 0.75rem;
  }
}