/* Calendar styles */

.calendar-wrapper .week-wrapper {
  display: flex;
  position: relative;
  flex-flow: row;
}

.calendar-wrapper .day-title {
  z-index: 2;
  cursor: pointer;
  left: 0;
  padding-top: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 1px 0 0 0 rgb(100, 100, 100),
    0 1px 0 0 rgb(100, 100, 100),
    1px 1px 0 0 rgb(100, 100, 100),
    1px 0 0 0 rgb(100, 100, 100) inset,
    0 1px 0 0 rgb(100, 100, 100) inset;
}

.calendar-wrapper .week-number:hover {
  background-color: rgb(100, 100, 100, 0.3);
}

.calendar-wrapper .day-wrapper {
  position: relative;
  /* flex-grow: 1; */
}


.calendar-wrapper .day-wrapper .day.shooting-day {
  height: 100%;
  min-height: 170px;
  display: flex;
  flex-flow: column;
  box-shadow:
    1px 0 0 0 rgb(200, 200, 200),
    0 1px 0 0 rgb(200, 200, 200),
    1px 1px 0 0 rgb(200, 200, 200),
    /* Just to fix the corner */
    1px 0 0 0 rgb(200, 200, 200) inset,
    0 1px 0 0 rgb(200, 200, 200) inset;
}

.calendar-wrapper .columns {
  padding: 0;
  min-width: 250px;
}

.calendar-wrapper .mini-calendar .day-wrapper .day.shooting-day {
  min-height: 115px;
}

.calendar-wrapper .mini-calendar .day-title {
  font-size: 10px;
}

.calendar-wrapper .today {
  border: 3px solid var(--yellow);
  box-shadow:
    1px 0 0 0 var(--yellow),
    0 1px 0 0 var(--yellow),
    1px 1px 0 0 var(--yellow),
    /* Just to fix the corner */
    1px 0 0 0 var(--yellow) inset,
    0 1px 0 0 var(--yellow) inset !important;
}

.calendar-wrapper .today .day-number>span {
  /* color: var(--yellow); */
  font-weight: bold;
}

/* Shooting day */

.shooting-day {
  padding: 6px;
}

.shooting-calendar {
  width: calc(100% - 4px);
  padding-bottom: 1px;
}

.shooting-day .day-number {
  text-align: right;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.shooting-day .day-number.day-events>div {
  float: left;
  max-width: calc(100% - 60px);
}

.shooting-day.holiday {
  background-color: rgb(240, 240, 240);
}

.shooting-day .day-shootings .shooting-card {
  display: flex;
  justify-content: space-between;
  padding: 3px 6px;
  margin-bottom: 3px;
}

.shooting-day .day-shootings .shooting-card:hover {
  opacity: 0.5;
}

.shooting-day .day-shootings {
  flex: 1;
  min-height: 6px;
}

.shooting-day .day-shootings .shooting-card p {
  margin: 0;
  font-size: 12px;
  text-align: left;
}

.shooting-day .day-events>div {
  position: relative;
  font-size: 10px;
  margin-bottom: 2px;
  text-align: left;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.shooting-day .day-events>div>span {
  max-height: 20px;
  display: block;
  overflow: hidden;
  line-height: 1;
}

.shooting-day .day-events:not(.day-number)>div>span:last-child {
  max-width: calc(100% - 20px);
}

.shooting-day .day-events>div>.material-icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
  margin-right: 3px;
  cursor: pointer;
}

.shooting-day .day-events>div>span:hover {
  max-height: initial;
  white-space: initial;
}

.shooting-day .day-events>div:last-of-type {
  margin-bottom: 0;
}

.shooting-day .day-events mat-icon {
  height: 16px;
  width: 16px;
  font-size: 16px;
  margin-right: 3px;
}

.shooting-day .day-events mat-icon:hover {
  color: var(--yellow);
}

.shooting-day .day-events mat-icon.options {
  margin: 0;
}

.shooting-day .day-number.day-events>span {
  margin-left: auto;
  text-transform: capitalize;
}

.shooting-calendar .info-icon {
  display: none;
  max-width: 200px;
}