/* https://codepen.io/goldsteinr/pen/ftysC?editors=1010 */

.switch-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.switch-wrapper .radio-wrapper {
  width: 42px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  position: relative;
}

.switch-wrapper .radio-wrapper.disabled {
  opacity: 0.6;
}

.switch-wrapper .radio-wrapper.disabled>input,
.switch-wrapper .radio-wrapper.disabled .right+label,
.switch-wrapper .radio-wrapper.disabled .left+label,
.switch-wrapper .radio-wrapper.disabled .neutral+label {
  cursor: not-allowed !important;
}

.radio-wrapper.blue {
  background: rgb(24, 184, 221, 0.7);
}

.radio-wrapper.red {
  background: rgb(230, 108, 103, 0.7);
}

.radio-wrapper.green {
  background: rgb(161, 220, 102, 0.7);
}

.switch-wrapper>p {
  margin: 0 0.5rem !important;
  font-size: 11px;
}

.switch-wrapper .radio-wrapper p {
  position: absolute;
  color: white;
  font-size: 1.7em;
  margin: 0;
  margin-top: 13px;
}

.switch-wrapper .radio-wrapper .correct {
  left: 17px;
  top: -5px;
}

.switch-wrapper .radio-wrapper .wrong {
  right: 17px;
  top: -5px;
}

.switch-wrapper .radio-wrapper .neutral-icon {
  left: 69px;
  top: -8px;
  opacity: 0.5;
}

.switch-wrapper input[type="radio"] {
  display: none;
}

.switch-wrapper i {
  /* z-index: 99; */
  font-size: 18px;
}

.switch-wrapper .neutral-icon i {
  font-size: 10px;
}

.switch-wrapper label {
  margin: 0;
}

.switch-wrapper .neutral+label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 1s;
  cursor: pointer;
}

.switch-wrapper .neutral:checked+label {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 2px 0;
  border-radius: 50%;
  border: 1px solid rgb(50, 50, 50, 0.8);
}

.switch-wrapper .left+label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  text-align: center;
  cursor: pointer;
}

.switch-wrapper .left:checked+label {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 50%;
  animation-duration: 0.4s;
  animation-name: slidein;
  border: 1px solid rgb(50, 50, 50, 0.8);
}

.switch-wrapper .right+label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  text-align: center;
  cursor: pointer;
}

.switch-wrapper .right:checked+label {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 50%;
  animation-duration: 0.4s;
  animation-name: slideno;
  border: 1px solid rgb(50, 50, 50, 0.8);
}


.switch-wrapper input.red:checked+label {
  background: #e66c67;
  background: linear-gradient(to bottom, rgba(230, 108, 103, 1) 0%, rgba(221, 79, 75, 1) 100%);
}

.switch-wrapper input.green:checked+label {
  background: #B7FA72;
  background: linear-gradient(to bottom, rgba(183, 250, 114, 1) 0%, rgba(147, 200, 92, 1) 100%);
}

.switch-wrapper input.grey:checked+label {
  background: #eee;
  background: linear-gradient(to bottom, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
}

.switch-wrapper input.blue:checked+label {
  background: #19cff9;
  background: linear-gradient(to bottom, rgba(25, 207, 249, 1) 0%, rgba(21, 157, 188, 1) 100%);
}

@keyframes slidein {
  from {
    transform: translate(20px, 0);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes slideno {
  from {
    transform: translate(-20px, 0);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes returnLeft {
  from {
    transform: translate(-20px, 0);
  }

  to {
    transform: translate(0px, 0);
  }
}

@keyframes returnRight {
  from {
    transform: translate(20px, 0);
  }

  to {
    transform: translate(0px, 0);
  }
}