/*Graficas*/
.graph-legend {
    display: flex;
    padding: 0 0.188em;
  }
  
  .graph-legend.vertical {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  
  .graph-legend.horizontal {
    flex-flow: wrap;
    justify-content: center;
  }

  .graph-legend.horizontal .legend-item{
      padding: 0 5px;
  }
  
  .graph-legend .legend-item {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
  }
  
  .graph-legend .legend-item .circle {
    display: inline-block;
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    margin: 0 3px;
  }
  
  .graph-legend .legend-item .circle.black {
    background-color: #000000;
  }
  
  .graph-legend .legend-item .circle.green {
    background-color: var(--green);
  }
  
  .graph-legend .legend-item .circle.yellow {
    background-color: var(--yellow);
  }
  
  .graph-legend .legend-item .circle.blue {
    background-color: var(--blue);
  }
  
  .graph-legend .legend-item .circle.red {
    background-color: var(--red);
  }
  
  .graph-legend .legend-item .circle.purple {
    background-color: var(--purple);
  }
  
  .graph-legend .legend-item .circle.grey {
    background-color: #6a6a6a;
  }
  
  .graph-legend .graph-legend-button {
    width: 100%;
    margin: 6px 0;
    position: relative;
    height: 16px;
  }
  
  .graph-legend .graph-legend-button .mat-icon-button {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .graph-legend .graph-legend-button.hidden {
    visibility: hidden;
  }